.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.show-inline {
  display: inline-block !important;
}

.main-wrapper {
  padding-bottom: 50px;
}

.w-100 {
  width: 100%;
}

.mdl-layout__header {
  width: 100%;
}

.rodal, .rodal-mask {
  z-index: 10000 !important;
}

.rodal-dialog {
  text-align: center;
  max-width: 420px;
  z-index: 10000 !important;
}

.rodal-title,
.rodal-body {
  padding: 10px 0;
}
.rodal-footer {
  padding: 20px 0 0;
  display: flex;
  justify-content: space-around;
}

/* EVENTTICKET */

.eventticket-datetime-container {
  font-size: 18px;
}
.eventticket-datetime-container .dayName,
.eventticket-datetime-container .month {
  margin: 0 0.5rem;
}

.eventticket--profile-performances .eventticket-datetime-container {
  display: inline-block;
  margin: 0 0.5rem;
}

.tickets-list-table .eventticket-datetime-container {
  display: inline-block;
  margin: 0 0.5rem;
}

/* CHECKOUT MAP */
.eventticket-cho-map svg {
  width: 100%;
  height: 300px;
}
.eventticket-cho-map circle,
.eventticket-cho-map rect {
  cursor: pointer;
}
.eventticket-cho-map circle:hover,
.eventticket-cho-map rect:hover {
  opacity: 0.5;
  filter: hue-rotate(90deg);
}
.eventticket-cho-map circle.selected,
.eventticket-cho-map rect.selected {
  filter: hue-rotate(90deg);
  /*fill: deeppink;
  opacity: 0.5;*/
  fill: yellow;
  opacity: 0.8;
}
.eventticket-cho-map circle[frontendfiltered="1"],
.eventticket-cho-map rect[frontendfiltered="1"],
.eventticket-cho-map circle[frontendfiltered="true"],
.eventticket-cho-map rect[frontendfiltered="true"] {
  fill: #ccc;
}
.eventticket-cho-map circle[seatdisabled="1"],
.eventticket-cho-map rect[seatdisabled="1"],
.eventticket-cho-map circle[seatdisabled="true"],
.eventticket-cho-map rect[seatdisabled="true"] {
  /*fill: #ccccccaa;*/
}

@media screen and (min-width: 640px) {
  .eventticket-cho-map svg {
    width: 100%;
    height: 600px;
  }
}

.eventticket-reference-color {
  display: inline-block;
  width: 25px;
  height: 25px;
  vertical-align: middle;
  margin-right: 10px;
}

/* SVG PAN ZOOM */
#svg-pan-zoom-controls {
  /*transform: scale(1);*/
}

/* STRIPE FORM */
.eventticket-card-form {
  max-width: 720px;
  margin: auto;
  padding: 20px;
  text-align: center;
}

.eventticket-card-form b {
  font-size: 12px;
  color: #666;
  margin-top: 40px;
  display: inline-block;
}

.eventticket-card-form img {
  width: 70%;
  margin: 6px 0 20px;
  max-width: 200px; /* only stripe logos by now */
}

.eventticket-card-form---input {
  border: 1px solid #ccc;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;

  margin-bottom: 20px;
}

.eventticket-card-form--info-label {
  margin-bottom: 20px;
}

.eventticket--mobile-zoom-in {
  position: absolute;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  background-image: radial-gradient(#eee, transparent 80%);
  color: #000;
  font-weight: bold;
}

.eventticket--mobile-zoom-in span {
  width: 100%;
  display: inline-block;
  top: 45%;
  position: relative;
  text-align: center;
}

.eventticket--mobile-zoom-out {
  color: #1636a8;
  background-color: #fff;
  border: 4px solid #1636a8;
  border-radius: 10px;
  display: none;
  position: relative;
  bottom: 70px;
  padding: 4px;
  z-index: 1;
}

.eventticket--mobile-zoom-out .fa-search-minus {
  font-size: 30px;
}

.eventticket-cho-cart-review {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 100;
  padding: 0 20px 10px;
  border-top: 1px solid #ccc;
  background: #333;
  border-top: 1px solid #ccc;
  color: #fff;
  left: 0;
  right: 0;
}

.eventticket-cho-cart-review-toggle {
  padding: 20px 10px 0 0;
  margin: 0 10px 10px 10px;
  cursor: pointer;
}

.eventticket-cho-cart-review-toggle:hover {
  /*background-color: #eee;*/
}

.eventticket-cho-cart-review--ticket {
  display: inline-block;
}

/* MOBILE **/
@media screen and (max-width: 420px) {
  .all-events-profiles .all-events-profiles__box {
    max-width: initial;
    width: 100%;
  }
}

.primary-color {
  color: #f00;
}

/* BOOTSTRAP 4 OVERWRITES*/
.p-0 {
  padding: 0 !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.mr-0 {
  margin-right: 0 !important;
}

/* BOOTSTRAP 3 HELPERS CUSTOM */
.padding-xs { padding: .25em; }
.padding-sm { padding: .5em; }
.padding-md { padding: 1em; }
.padding-lg { padding: 1.5em; }
.padding-xl { padding: 3em; }

.padding-x-xs { padding: .25em 0; }
.padding-x-sm { padding: .5em 0; }
.padding-x-md { padding: 1em 0; }
.padding-x-lg { padding: 1.5em 0; }
.padding-x-xl { padding: 3em 0; }

.padding-y-xs { padding: 0 .25em; }
.padding-y-sm { padding: 0 .5em; }
.padding-y-md { padding: 0 1em; }
.padding-y-lg { padding: 0 1.5em; }
.padding-y-xl { padding: 0 3em; }

.padding-top-xs { padding-top: .25em; }
.padding-top-sm { padding-top: .5em; }
.padding-top-md { padding-top: 1em; }
.padding-top-lg { padding-top: 1.5em; }
.padding-top-xl { padding-top: 3em; }

.padding-right-xs { padding-right: .25em; }
.padding-right-sm { padding-right: .5em; }
.padding-right-md { padding-right: 1em; }
.padding-right-lg { padding-right: 1.5em; }
.padding-right-xl { padding-right: 3em; }

.padding-bottom-xs { padding-bottom: .25em; }
.padding-bottom-sm { padding-bottom: .5em; }
.padding-bottom-md { padding-bottom: 1em; }
.padding-bottom-lg { padding-bottom: 1.5em; }
.padding-bottom-xl { padding-bottom: 3em; }

.padding-left-xs { padding-left: .25em; }
.padding-left-sm { padding-left: .5em; }
.padding-left-md { padding-left: 1em; }
.padding-left-lg { padding-left: 1.5em; }
.padding-left-xl { padding-left: 3em; }

.margin-xs { margin: .25em; }
.margin-sm { margin: .5em; }
.margin-md { margin: 1em; }
.margin-lg { margin: 1.5em; }
.margin-xl { margin: 3em; }

.margin-x-xs { margin: .25em 0; }
.margin-x-sm { margin: .5em 0; }
.margin-x-md { margin: 1em 0; }
.margin-x-lg { margin: 1.5em 0; }
.margin-x-xl { margin: 3em 0; }

.margin-y-xs { margin: 0 .25em; }
.margin-y-sm { margin: 0 .5em; }
.margin-y-md { margin: 0 1em; }
.margin-y-lg { margin: 0 1.5em; }
.margin-y-xl { margin: 0 3em; }

.margin-top-xs { margin-top: .25em; }
.margin-top-sm { margin-top: .5em; }
.margin-top-md { margin-top: 1em; }
.margin-top-lg { margin-top: 1.5em; }
.margin-top-xl { margin-top: 3em; }

.margin-right-xs { margin-right: .25em; }
.margin-right-sm { margin-right: .5em; }
.margin-right-md { margin-right: 1em; }
.margin-right-lg { margin-right: 1.5em; }
.margin-right-xl { margin-right: 3em; }

.margin-bottom-xs { margin-bottom: .25em; }
.margin-bottom-sm { margin-bottom: .5em; }
.margin-bottom-md { margin-bottom: 1em; }
.margin-bottom-lg { margin-bottom: 1.5em; }
.margin-bottom-xl { margin-bottom: 3em; }

.margin-left-xs { margin-left: .25em; }
.margin-left-sm { margin-left: .5em; }
.margin-left-md { margin-left: 1em; }
.margin-left-lg { margin-left: 1.5em; }
.margin-left-xl { margin-left: 3em; }


.eventticket-cho-map-buttons {
  transform: translate(0, -100%);
  display: inline-block;
  position: absolute;
  right: 20px;
}
.eventticket-cho-map-buttons svg {
  max-height: 30px;
}
.eventticket-cho-map-buttons div {
  background-color: #ccc;
  cursor: pointer;
  width: 50px;
  padding: 5px;
  font-size: 16px;
  line-height: 0;
  font-weight: bold;
  border-radius: 10px;
  text-align: center;
  text-transform: uppercase;
  min-height: 20px;
  margin: 5px auto;
  align-self: flex-end;
  justify-self: flex-end;
  box-shadow: solid #222 0;
}


/**
APP REFRESH
 */

:root {
  --primary-color: #0e0e0f;
  --secondary-color: #1d293e;
  --highlight-color: #c6c6c8;
  --text-color-dark: #5f5f5f;
  --text-color-dark2: #0e0e0f;
  --text-color-light: #fff;
  --body-bg-color: #f0ecec;
  --box-bg-color: #fff;
}

body {
  margin: 0px;
  /*font-family: 'Poppins', sans-serif !important;*/
  /*background-color: #f0ecec;
  background-color: var(--body-bg-color);
  color: #5f5f5f;
  color: var(--text-color-dark);*/
  /*font-size: 18px;*/
}

main {
  min-height: 100vh;
}

section {
  padding: 60px 0 40px 0;
}

h1 {
  text-align: center;
}

h2 {
  font-size: 24px;
  font-weight: 400;
}

a,
a:hover {
  cursor: pointer;
}

/*a,
a:hover {
  text-decoration: none;
  color: inherit;
}*/

.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.8);
}

/* Modal */
.custom-modal {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 40px;
}

.custom-modal h2 {
  font-size: 24px;
}

.box {
  background-color: var(--box-bg-color);
  border-radius: 10px;
  -webkit-box-shadow: -1px 0px 40px -29px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -1px 0px 40px -29px rgba(0, 0, 0, 0.75);
  box-shadow: -1px 0px 40px -29px rgba(0, 0, 0, 0.75);
}

.btn-container .btn-primario {
  width: 150px;
  margin: 10px auto;
}

.btn-primario {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0e0e0f;
  background-color: var(--primary-color);
  color: #fff;
  color: var(--text-color-light);
  padding: 12px 20px;
  text-decoration: none;
  border-radius: 25px;
  border-color: #fff;
  border: 2px solid var(--text-color-light);
  font-size: 12px;
  font-family: 'Montserrat', sans-serif;
  text-align: center;
  text-transform: uppercase;
}

.btn-primario:hover,
.btn-primario:focus {
  color: var(--highlight-color);
}

/* Header */

.top-header {
  height: 90px;
  background-color: #0e0e0f;
  background-color: var(--primary-color);
}

.logo-header-container {
  max-width: 270px;
  height: 60px;
}


.logo-footer,
.logo-header {
  object-fit: contain;
  height: 100%;
}

.box-login {
  float: right;
}

.box-login a {
  color: var(--text-color-light);
  text-decoration: none;
  font-size: 13px;
  font-weight: 500;
}

.box-login a img {
  padding-right: 10px;
}

.box-login a:hover,
.box-login a:focus {
  color: var(--highlight-color);
}

#event-banner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--text-color-light);
  min-height: 400px;
  background-color: var(--secondary-color);
  background-size: cover;
  background-position-x: center;
}

#event-banner .event-title {
  padding-top: 55px;
}

/* #clockdiv {
	font-family: sans-serif;
	color: var(--text-color-light);
	display: inline-block;
	font-weight: 100;
	text-align: center;
	font-size: 30px;
}

#clockdiv > div {
	padding: 10px;
	display: inline-block;
}

#clockdiv div > span {
	padding: 15px;
	display: inline-block;
} */

.smalltext {
  padding-top: 5px;
  font-size: 16px;
}



/* HERO FEATURED EVENTS */
#featured-event-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 650px;
  background-color: var(--secondary-color);
  background-size: cover;
  background-position-x: center;
  color: var(--text-color-light);
}

#featured-event-container .event-date p {
  text-align: center;
  margin-bottom: 0px;
  font-size: 18px;
  text-transform: uppercase;
}

#featured-event-container .event-date span {
  text-align: center;
  display: block;
  color: var(--highlight-color);
  font-size: 18px;
}

.event-title h1 {
  font-size: 40px;
  font-weight: 900;
  font-family: 'Montserrat', sans-serif;
}

.event-title {
  padding-top: 30px;
}

.event-title img {
  margin-left: 20px;
  margin-right: 20px;
}

/* Contador */
.countdown-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

.big-text {
  font-weight: 600;
  font-size: 40px;
  color: var(--highlight-color);
}

.cont-el p {
  margin-bottom: 0px;
  padding-top: 28px !important;
  line-height: 32px;
}

.cont-el {
  text-align: center;
  width: 113px;
  margin: 0 15px;
  border-radius: 50%;
  border: 4px solid var(--text-color-light);
  height: 113px;
}
.cont-el span {
  font-size: 14px;
  color: var(--text-color-light);
}

.date {
  margin: 85px 0 0 0;
  padding: 10px 25px;
  font-size: 1.1rem;
  border: none;
  border-radius: 5px;
}

#featured-event-container .btn-primario {
  width: 140px;
  margin: 40px auto 0px;
}

/* Events */

.titulares h2 {
  text-align: center;
  margin-bottom: 60px;
}

#upcoming-events a:hover {
  text-decoration: none;
}

.box-event {
  color: var(--text-color-light);
  min-height: 280px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.box-event:hover {
  background-size: 150%;
}

.box-event h3 {
  font-size: 24px;
  text-align: center;
  margin: 8px 0;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
}

.box-event .info-evento p {
  text-align: center;
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  margin-bottom: 10px;
}

/* .box-event img {
	object-fit: cover;
    max-width: 100%;
    width: 320px;
} */


/* FAQ */

.box-faq {
  min-height: 450px;
  margin-bottom: 40px;
}

.box-img-faq {
  background-size: cover;
  min-height: 450px;
  background-color: var(--secondary-color);
  -webkit-border-top-left-radius: 10px;
  -webkit-border-bottom-left-radius: 10px;
  -moz-border-radius-topleft: 10px;
  -moz-border-radius-bottomleft: 10px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.sinpad {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.bloque-faq {
  padding: 20px;
  padding-left: 0;
}

.titular-faq {
  padding-top: 30px;
}

/* TODO revisar estos estilos */
.titular-faq p {
  font-size: 18px;
  color: var(--primary-color);
  font-weight: 500;
  margin-bottom: 0px;
}

/* TODO revisar estos estilos */
.titular-faq h4 {
  font-size: 55px;
  color: var(--primary-color);
  font-weight: 900;
  margin-top: 0px;
  padding-top: 0px;
  line-height: 50px;
}

/* TODO revisar estilos repetidos */
.bloque-faq .accordion-button:not(.collapsed) {
  color: #fff;
  color: var(--text-color-light);
  background-color: #0e0e0f;
  background-color: var(--primary-color);
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125);
}

.bloque-faq .accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px 15px;
  font-size: 15px;
  color: #fff;
  color: var(--text-color-light);
  background-color: #0e0e0f;
  background-color: var(--primary-color);
}

.bloque-faq .accordion-body {
  font-size: 15px;
  color: #0e0e0f;
  color: var(--text-color-dark2);
}

.box-novedad {
  min-height: 280px;
  padding: 0px 20px 0px 20px;
}

/* Últimos eventos */

/* #ultimos-eventos {
	padding-bottom: 90px;
	padding-top: 30px;
}

.nota img {
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	border-radius: 10px;
}

.nota {
	position: relative;
}

.textos-nota {
	position: absolute;
	bottom: 8px;
	left: 0px;
	width: 100%;
	padding-left: 25px;
	padding-right: 25px;
}

.textos-nota span {
	color: var(--highlight-color);
	font-size: 15px;
	font-weight: 600;
}

.textos-nota p {
	color: var(--text-color-light);
	font-size: 18px;
	font-weight: 600;
	line-height: 16px;
} */

#footer {
  padding: 30px;
  color: var(--text-color-light);
  background-color: var(--secondary-color);
}

#footer a:hover {
  text-decoration: underline;
}

footer .row {
  max-width: 350px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.bloque-footer {
  width: 350px;
  margin: auto;
}

.logo-footer {
  max-width: 130px;
}

.redes a {
  margin-right: 10px;
}

footer p {
  font-size: 14px;
  font-weight: 500;
  margin: 10px 0 0;
}

#contenedor-operacion,
#contenedor-compra,
#event-details {
  padding-top: 60px;
}

.box-operacion,
.box-details {
  min-height: auto;
  margin-bottom: 40px;
  padding-bottom: 10px;
}

.titular-ubicaciones {
  border-bottom: 1px solid var(--body-bg-color);
}

.titular-ubicaciones h3 {
  font-size: 18px;
  color: var(--primary-color);
  text-align: center;
  padding-top: 40px;
  font-weight: 500;
  padding-bottom: 20px;
}

/* TODO revisar si estan en uso estos estilos */
.box-operacion .nav-pills .nav-link.active,
.box-operacion .nav-pills .show > .nav-link,
.box-details .nav-pills .nav-link.active,
.box-details .nav-pills .show > .nav-link {
  color: var(--text-color-dark2);
  background-color: var(--body-bg-color);
  border-left: 6px solid var(--primary-color);
}

.box-operacion .nav-pills .nav-link,
.box-details .nav-pills .nav-link {
  border-radius: 0px;
  text-align: left;
  border-left: 6px solid var(--text-color-light);
  font-weight: 600;
  font-size: 17px;
  padding-top: 20px;
  padding-bottom: 25px;
  border-bottom: 1px solid var(--body-bg-color);
}

.box-operacion .nav-pills .nav-link:last-child,
.box-details .nav-pills .nav-link:last-child {
  border-bottom: 0px solid var(--body-bg-color);
}

.item-ubicacion::before {
  content: '';
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-image: url('/img/geo-alt.svg');
  width: 25px;
  height: 25px;
}

.performances .event-map {
  margin: 10px 0 30px;
}

.performances span {
  margin-right: 5px;
}

.performance {
  margin-bottom: 10px;
}

.event-time {
  font-size: 14px;
}

.event-time::before {
  content: '';
  width: 100%;
  display: inline-block;
  vertical-align: text-top;
  background-image: url('/img/clock.svg');
  background-repeat: no-repeat;
  background-position: center;
  width: 18px;
  height: 18px;
  margin-right: 8px;
}

.buy-tickets .btn-primario {
  width: 200px;
  height: 74px;
  font-size: 16px;
}


.event-time p,
.item-ubicacion p {
  margin-bottom: 0px;
  padding-left: 30px;
  font-size: 15px;
  color: var(--text-color-dark2);
  font-weight: 400;
}

.item-ubicacion {
  position: relative;
  margin-bottom: 10px;
  margin-top: 10px;
}

#contenedor-compra .box-operacion {
  padding: 50px;
}

.event-img img {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}

.datos-compra,
.event-description {
  margin-left: 20px;
}

.datos-compra .form-control {
  padding: 9px 18px;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.5;
  color: var(--text-color-dark2);
  border: 1px solid var(--body-bg-color);
  border-radius: 8px;
}

.datos-compra .form-select {
  padding: 9px 18px;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.5;
  color: var(--text-color-dark2);
  border: 1px solid var(--body-bg-color);
  border-radius: 8px;
}

.titular-compra h3,
.event-title h3 {
  color: var(--text-color-dark2);
  font-size: 18px;
  font-weight: 400;
  padding-left: 50px;
  margin-bottom: 30px;
  padding-top: 10px;
  position: relative;
}

.titular-compra h3:before,
.event-title h3:before {
  content: '';
  width: 100%;
  position: absolute;
  left: 0;
  top: 20px;
  background-color: var(--primary-color);
  width: 38px;
  height: 3px;
}

.formulario-compra .btn-primario {
  margin-top: 25px;
}

.datos-compra .form-label {
  font-weight: 600;
  color: var(--text-color-dark2);
  font-size: 15px;
}

.datos-compra .form-check {
  font-weight: 600;
  color: var(--text-color-dark2);
  font-size: 15px;
}

.box-resumen p strong {
  margin-right: 10px;
}


.box-resumen p {
  margin-bottom: 6px;
}

.box-resumen .item-ubicacion::before {
  left: 58px;
}

.box-resumen .item-horario::before {
  left: 58px;
}

.box-resumen {
  border-bottom: 1px solid var(--body-bg-color);
  padding-bottom: 25px;
}

.box-totales p {
  text-align: right;
  color: var(--text-color-dark2);
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 8px;
}

.box-totales p strong {
  font-size: 15px;
  margin-right: 20px;
}

.box-totales {
  padding-top: 30px;
  padding-bottom: 25px;
}

.st-total {
  color: var(--primary-color) !important;
}

.boton-ubicaciones-xs .btn-primario {
  margin: 10px auto 20px !important;
}

#faq .accordion-button::after {
  background-image: url('/img/plus.svg');
}
#faq .accordion-button:not(.collapsed)::after {
  background-image: url('/img/dash.svg');
}

#faq .accordion-button:focus {
  box-shadow: 0 0 3px 0.15rem rgba(240, 236, 236, 0.25);
}

.buy-tickets {
  margin: 24px 0 20px;
}

#event-details .box-details {
  padding: 50px;
}

.event-title h3 span {
  font-size: 14px;
  color: var(--primary-color);
  font-weight: 500;
}

#event-details h4 {
  color: var(--text-color-dark2);
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 15px;
}


.event-description ul {
  padding-left: 0px;
  margin-bottom: 30px;
}

.event-description ul li {
  list-style: none;
  position: relative;
  padding-left: 18px;
}

.event-description ul li:before {
  content: '·';
  position: absolute;
  left: 0;
  top: 0;
  color: var(--highlight-color);
  font-size: 35px;
  line-height: 22px;
}

.event-description .btn-primario {
  margin-top: 5px;
}

.event-location {
  margin-top: 30px;
}

.event-location iframe {
  width: 100%;
  height: 100%;
}

/* Media queries */
@media (max-width: 575.98px) {
  /*`sm` applies to x-small devices (portrait phones, less than 576px)*/

  .custom-modal {
    padding: 20px;
  }

  .logo-header-container {
    height: 45px;
  }

  .event-title img {
    display: none;
  }

  .event-title h1 {
    font-size: 18px;
  }

  .cont-el {
    text-align: center;
    width: 70px;
    margin: 0 7px;
    border-radius: 50%;
    border: 4px solid var(--text-color-light);
    height: 70px;
  }

  .cont-el p {
    margin-bottom: 20px;
    padding-top: 15px !important;
    line-height: 32px;
  }

  .big-text {
    font-weight: 600;
    font-size: 32px;
    color: var(--highlight-color);
  }

  #event-banner {
    min-height: 250px;
  }

  .countdown-container {
    margin-top: 30px;
  }

  .box-login a {
    color: var(--text-color-light);
    text-decoration: none;
    font-size: 13px;
    font-weight: 500;
  }

  #featured-event-container {
    min-height: 500px !important;
  }

  .box-event {
    margin-bottom: 25px;
    padding-bottom: 25px;
  }

  #faq {
    padding-top: 0px;
  }

  .box-img-faq {
    min-height: 300px;
    background-color: #444;
    -webkit-border-top-left-radius: 0px;
    -webkit-border-bottom-left-radius: 0px;
    -moz-border-radius-topleft: 0px;
    -moz-border-radius-bottomleft: 0px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  .titular-faq {
    padding-top: 30px;
    padding-left: 20px;
  }

  .box-novedad {
    margin-bottom: 25px;
  }

  #ultimos-eventos {
    padding-bottom: 20px;
  }

  #contenedor-compra .box-operacion {
    padding: 0px;
  }

  .datos-compra {
    margin-left: 20px;
    margin-top: 20px;
    margin-right: 20px;
  }

  #contenedor-compra .box-operacion {
    padding-bottom: 30px;
  }

  .event-img img {
    -webkit-border-radius: 10px;
    -webkit-border-bottom-right-radius: 0px;
    -webkit-border-bottom-left-radius: 0px;
    -moz-border-radius: 10px;
    -moz-border-radius-bottomright: 0x;
    -moz-border-radius-bottomleft: 0x;
    border-radius: 10px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  .box-totales {
    margin-left: 20px;
  }

  .box-totales p {
    text-align: left;
  }

  .box-totales {
    padding-bottom: 0;
  }

  .bloque-footer {
    width: 307px;
    margin: auto;
  }

  .titulares h2 {
    margin-bottom: 34px;
  }

  #contenedor-operacion,
  #contenedor-compra,
  #event-details {
    padding-top: 35px;
  }

  .m-enxs {
    margin-top: 15px !important;
  }

  .performances {
    padding: 20px;
  }

  .event-time {
    display: block;
  }
}

@media (max-width: 767.98px) /* `md` applies to small devices (landscape phones, less than 768px) */ {

  h2 {
    font-size: 18px;
  }

  #footer {
    height: auto;
  }

  .redes {
    margin-top: 20px;
    text-align: center;
  }

  .event-title img {
    display: none;
  }

  .event-title h1 {
    font-size: 25px;
  }

  #event-banner {
    min-height: 320px;
  }

  #featured-event-container {
    min-height: 540px;
  }

  #faq {
    padding-top: 0px;
  }

  .box-img-faq {
    min-height: 300px;
    background-color: #444;
    -webkit-border-top-left-radius: 0px;
    -webkit-border-bottom-left-radius: 0px;
    -moz-border-radius-topleft: 0px;
    -moz-border-radius-bottomleft: 0px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  .bloque-faq {
    padding: 20px;
  }

  .titular-faq {
    padding-top: 30px;
    padding-left: 20px;
  }

  .box-novedad {
    margin-bottom: 25px;
  }

  #ultimos-eventos {
    padding-bottom: 20px;
  }

  #contenedor-compra .box-operacion {
    padding: 0px;
  }

  #event-details .box-operacion {
    padding: 0px;
  }

  .datos-compra,
  .event-description {
    margin-left: 20px;
    margin-top: 20px;
    margin-right: 20px;
  }

  .event-location {
    margin-top: 30px;
    margin-right: 20px;
    margin-left: 20px;
  }

  #contenedor-compra .box-operacion {
    padding-bottom: 30px;
  }

  .event-img img {
    -webkit-border-radius: 10px;
    -webkit-border-bottom-right-radius: 0px;
    -webkit-border-bottom-left-radius: 0px;
    -moz-border-radius: 10px;
    -moz-border-radius-bottomright: 0x;
    -moz-border-radius-bottomleft: 0x;
    border-radius: 10px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  .box-totales {
    margin-left: 20px;
  }

  .box-totales p {
    text-align: left;
  }

  /* 	.box-operacion .btn-primario,
      .box-details .btn-primario {
          width: 215px;
          margin: 40px auto 0px;
          display: block;
          float: none !important;
      } */

  .box-totales {
    padding-bottom: 0;
  }

  .titulares h2 {
    margin-bottom: 34px;
  }

  #contenedor-operacion,
  #contenedor-compra,
  #event-details {
    padding-top: 35px;
  }
}

@media (max-width: 991.98px) /*`lg` applies to medium devices (tablets, less than 992px) */ {
  .event-title h1 {
    font-size: 25px;
  }

  .box-novedad {
    margin-bottom: 25px;
  }

  .box-event {
    margin-bottom: 25px;
    padding-bottom: 25px;
  }

  #faq {
    padding-top: 0px;
  }

  #contenedor-compra .box-operacion,
  #event-details .box-details {
    padding: 0px;
  }

  .datos-compra,
  .event-description {
    margin-left: 20px;
    margin-top: 20px;
    margin-right: 20px;
  }

  .event-location {
    margin-top: 30px;
    margin-right: 20px;
    margin-left: 20px;
  }

  #contenedor-compra .box-operacion,
  #event-details .box-details {
    padding-bottom: 30px;
  }

  .event-img img {
    -webkit-border-radius: 10px;
    -webkit-border-bottom-right-radius: 0px;
    -webkit-border-bottom-left-radius: 0px;
    -moz-border-radius: 10px;
    -moz-border-radius-bottomright: 0x;
    -moz-border-radius-bottomleft: 0x;
    border-radius: 10px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  .box-totales {
    margin-left: 20px;
  }

  .box-totales p {
    text-align: left;
  }

  .box-operacion .btn-primario,
  .box-details .btn-primario {
    width: 215px;
  }

  .box-totales {
    padding-bottom: 0;
  }

}

.event-profile-data__event-description img,
.event-profile-data__event-description iframe {
  max-width: 100%;
}
